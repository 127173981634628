import {FC, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import moment from 'moment';
//firebase
import {messaging} from './index';
//translate
import i18n from 'components/translate';
//volunteer pages
import FeedPage from 'pages/Volunteers/FeedV';
import FeedViewItem from 'pages/Volunteers/FeedViewItemV';
import MyFeedPage from 'pages/Volunteers/MyFeedV';
import DiplomaPage from 'pages/Volunteers/Diploma';
import About from 'pages/Volunteers/About';
import GetInTouch from 'pages/Volunteers/GetInTouch';
import VolunteerChat from 'pages/Volunteers/ChatV';
import EditVolunteer from 'pages/Volunteers/EditVolunteer';
import CreateManualOppoV from 'pages/Volunteers/CreateManualOppoV';
import MainPageLayoutV from 'components/Volunteer/MainPageLayoutV';
import PaymentCongrats from 'pages/Volunteers/PaymentCongrats';
import VerifyManualOpportunity from './pages/Volunteers/VerifyManualOpportunity';
import UpdateManualOpportunity from 'pages/Volunteers/UpdateManualOpportunity';
//onboarding pages
import ChooseUserType from 'pages/ChooseUserType';
import LoginFromEmail from 'pages/LoginFromEmail';
import RecoveryPassword from 'pages/Organization/RecoveryPassword';
import LoginOrg from 'pages/Login/LoginOrg';
import AboutYouSignup from 'pages/Organization/SignUp/AboutYouSignup';
import AboutOrgSignup from 'pages/Organization/SignUp/AboutOrgSignup';
import ContactOrgSignup from 'pages/Organization/SignUp/ContactOrgSignup';
import InviteColleaguesSignup from 'pages/Organization/SignUp/InviteColleaguesSignup';
import LoginVolunteer from 'pages/Login/LoginVolunteer';
import SignUpV from 'pages/Volunteers/SignUpV';
import SignUpCongratulation from 'pages/SignUpCongratulations';
import OnboardingContentContainer from 'shared/components/OnboardingContentContainer';
import ClaimJoin from 'pages/Claim/ClaimJoin';
import ClaimPending from './pages/Claim/ClaimPending';
import ClaimCongratulations from './pages/Claim/ClaimCongratulations';
import Cookies from './pages/Cookies';
//organization pages
import DashboardPage from 'pages/Organization/Dashboard';
import OpportunitiesPage from 'pages/Organization/Opportunities';
import VolunteersPage from 'pages/Organization/Volunteers';
import MarketingPage from 'pages/Organization/Marketing';
import InboxPage from 'pages/Organization/Inbox';
import Statistics from 'pages/Organization/Statistics';
import EditAccount from 'pages/Organization/EditAccount';
import EditOrg from 'pages/Organization/EditOrg';
import InviteColleagues from 'pages/Organization/InviteColleagues';
import MainPageLayout from 'components/Organization/MainPageLayout';
import MembersPage from 'pages/Organization/Members';
import OrganizationSettings from './pages/Organization/OrganizationSettings';
//shared pages
import BadGateway from 'pages/BadGataway';
import AccessDenied from 'pages/AccessDenied';
import SimpleCongrat from 'pages/SimpleCongrat';
import NotFound from 'pages/NotFound';
import Terms from 'pages/Terms';
import Policy from 'pages/Policy';
import ChooseStoreType from 'pages/ChooseStoreType';
import ConnectionError from 'pages/ConnectionError';
import TariffPlans from 'pages/TariffPlans';
import GuestFeed from 'pages/Volunteers/GuestFeed';
import Landing from 'pages/Landing';
import PublicDashboardPage from 'pages/PublicDashboard';
import {LiveScreenPage} from './pages/LiveScreen';
import {AcceptInviteCongrats, AcceptInviteError} from 'pages/AcceptInvite';
//route guards
import RouteGuard from 'shared/components/RouteGuard';
import RouteGuardWithGuest from 'shared/components/RouteGuard/RouteGuardWithGuest';
import RouteGuardLoginRequired from 'shared/components/RouteGuard/RouteGuardLoginRequired';
//redux
import {Store} from 'redux/root';
import {resetError} from 'redux/error-service/action';
import {UserToConnectType} from 'redux/chat-service/types';
import {getOrgInfo} from 'redux/organization-service/actions';
import {getCurrentUser, setUserNotificationToken} from 'redux/user-service/actions';
import {setPushMessage} from 'redux/push-service/action';
import {selectorGetPushMessage} from 'redux/push-service/selector';
import {selectorGetUserData} from 'redux/user-service/selector';
import {selectorGetError} from 'redux/error-service/selector';
import {
  chatClient,
  connectUserToGetStream,
  setChatUnreadMessagesCount,
  setChatUserToConnect,
} from 'redux/chat-service/actions';
import {selectorGetChatUnreadMessagesCount, selectorGetChatUserToConnect} from 'redux/chat-service/selector';
import {selectorGetOrgInfo} from 'redux/organization-service/selector';
//functions
import {fireBaseGetToken, setUserChatPhoto} from 'core/functions';
//urls
import {onboardingAllowedUrls, urls} from 'core/appUrls';
//types
import {IRolesResponse, VOLUNTEER} from '@joc/api-gateway';
//constants
import {LINKS_URLS, NOT_REDIRECTED_URLS} from 'core/constants';
//components
import SnackbarInfo from 'shared/components/Snackbars/SnackarInfo';
import Banner from 'shared/components/Banner';
import Loader from 'shared/components/Loader';
import SnackbarUploadInfo from 'shared/components/Snackbars/SnackbarUpload';
import HomePage from './pages/Volunteers/Home';

const App: FC<AppPropsType> = ({
  orgInfo,
  userData,
  getOrgInfo,
  userToConnect,
  getCurrentUser,
  setPushMessage,
  setChatUserToConnect,
  connectUserToGetStream,
  setUserNotificationToken,
  setChatUnreadMessagesCount,
}: AppPropsType) => {
  const history = useHistory();
  const location = useLocation();

  const fullFilledUserData = useSelector((store: Store) => store.userRedux.fullFilledData);

  useEffect(() => {
    const unSubscribe = messaging?.onMessage((payload) => {
      if (payload || userData?.notificationTokens?.length) {
        const {title, body} = payload.notification;

        setPushMessage({title, body});
      }
    });

    const pushToken = localStorage.getItem('pushToken');
    if (!pushToken) getFirebasePushMessageToken();

    return () => unSubscribe && unSubscribe();
  }, [messaging, userData?.fireBaseUserId, userData?.notificationTokens]);

  const [lang, setLang] = useState(
    i18n.language.split('-')[0].toUpperCase() || i18n.options.fallbackLng?.toString().toUpperCase() || 'EN'
  );

  const getChatUnreadMessagesCountListener = (event: any) => {
    // eslint-disable-next-line camelcase
    const {unread_count} = event;
    // eslint-disable-next-line camelcase
    if (unread_count !== undefined) {
      setChatUnreadMessagesCount(unread_count);
    }
  };

  const getFirebasePushMessageToken = () => {
    fireBaseGetToken()
      .then((token) => {
        localStorage.setItem('pushToken', token as string);
        setUserNotificationToken(token as string);
        return token;
      })
      .catch((err) => {
        localStorage.removeItem('pushToken');
        console.error('failed: ', err);
      });
  };

  const selectRedirectUrl = () => {
    const volunteerId = localStorage.getItem('volunteerId');
    if (userData?.roles?.find((i: IRolesResponse) => i.roleName === VOLUNTEER.VOLUNTEER) && volunteerId)
      return urls.volHome;
    if (
      userData?.roles?.find((i: IRolesResponse) => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.MARKETER)
    )
      return urls.orgDashboard;
    if (userData?.roles?.find((i) => i.roleName === VOLUNTEER.COORDINATOR)) return urls.orgOpportunities;

    return urls.onboardingChooseUserType;
  };

  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');
    const fullUrlPath = location.pathname;
    const pagePathname = fullUrlPath.slice(0, fullUrlPath.lastIndexOf('/'));

    if (
      !localStorageAccessToken &&
      !NOT_REDIRECTED_URLS.includes(
        [LINKS_URLS.feedViewLink, LINKS_URLS.confirmInvite, LINKS_URLS.publicDashboard].includes(pagePathname)
          ? pagePathname
          : fullUrlPath
      )
    ) {
      if (onboardingAllowedUrls.includes(fullUrlPath)) {
        history.push(fullUrlPath);
      } else {
        history.push(urls.onboardingChooseUserType);
      }
    }

    if (localStorageAccessToken) {
      if (
        pagePathname === LINKS_URLS.feedViewLink ||
        fullUrlPath === urls.onboardingClaimJoin ||
        fullUrlPath === urls.volGetInTouch ||
        fullUrlPath === urls.volAbout
      ) {
        getCurrentUser();
      }
      if (
        !NOT_REDIRECTED_URLS.includes(pagePathname === LINKS_URLS.feedViewLink ? pagePathname : fullUrlPath) ||
        fullUrlPath === urls.getInTouch
      ) {
        getCurrentUser();
        const localStorageOrgId = localStorage.getItem('organisationId');
        if (localStorageOrgId) getOrgInfo(localStorageOrgId);
      }
    }

    return () => {
      chatClient.disconnectUser();
      chatClient.off('notification.message_new', getChatUnreadMessagesCountListener);
      chatClient.off('notification.mark_read', getChatUnreadMessagesCountListener);
    };
  }, []);

  useEffect(() => {
    if (!userData?.fireBaseUserId) return;
    if (fullFilledUserData !== undefined && fullFilledUserData === false) {
      history.push({pathname: urls.volEditAccount, search: '?fullfilled=false'});
    }
  }, [userData, fullFilledUserData]);

  useEffect(() => {
    const localStorageVolunteerId = localStorage.getItem('volunteerId');
    const localStorageORGId = localStorage.getItem('organisationId');
    let userToConnect: UserToConnectType | null = null;
    if (orgInfo && localStorageORGId) {
      userToConnect = {
        id: orgInfo.chatId,
        name: orgInfo.organizationName,
        image: setUserChatPhoto(orgInfo.organizationLogoPath || ''),
        type: 'organisation',
      };
    } else if (userData?.chatId && userData?.volunteerId && localStorageVolunteerId) {
      userToConnect = {
        id: userData.chatId,
        name: `${userData.firstName} ${userData.lastName}`,
        image: setUserChatPhoto(userData.imagePath || ''),
        type: 'volunteer',
      };
    }

    if (userToConnect) setChatUserToConnect(userToConnect);
  }, [orgInfo, userData]);

  useEffect(() => {
    if (userToConnect && chatClient) {
      connectUserToGetStream(userToConnect);
      chatClient.on('message.new', getChatUnreadMessagesCountListener);
      chatClient.on('notification.message_new', getChatUnreadMessagesCountListener);
      chatClient.on('notification.mark_read', getChatUnreadMessagesCountListener);
    }
  }, [userToConnect]);

  useEffect(() => {
    return () => {
      chatClient.disconnectUser();
    };
  }, []);

  return (
    <>
      <SnackbarInfo />
      <Switch>
        <Route exact path={urls.main}>
          <Redirect push to={selectRedirectUrl()} />
        </Route>

        <Route path={urls.verification} component={VerifyManualOpportunity} />

        <Route path={urls.publicDashboard} component={PublicDashboardPage} />

        <Route path={urls.liveScreen} component={LiveScreenPage} />

        <Route path={urls.onboarding}>
          <Switch>
            <OnboardingContentContainer>
              <Route exact path={urls.onboardingChooseUserType} component={ChooseUserType} />
              <Route exact path={urls.onboardingChooseStoreType} component={ChooseStoreType} />
              <Route exact path={urls.onboardingLoginFromEmail} component={LoginFromEmail} />
              <Route exact path={urls.onboardingRecoveryPassword} component={RecoveryPassword} />
              <Route exact path={urls.onboardingLoginOrganization} component={LoginOrg} />
              <Route exact path={urls.onboardingOrgSignupAboutYou} component={AboutYouSignup} />
              <Route exact path={urls.onboardingOrgSignupAboutOrganization} component={AboutOrgSignup} />
              <Route exact path={urls.onboardingOrgSignupContacts} component={ContactOrgSignup} />
              <Route exact path={urls.onboardingOrgSignupInviteColleagues} component={InviteColleaguesSignup} />
              <Route exact path={urls.onboardingLoginVolunteer} component={LoginVolunteer} />
              <Route exact path={urls.onboardingSignupVolunteer} component={SignUpV} />
              <Route exact path={urls.onboardingSignupCongrat} component={SignUpCongratulation} />
              <Route exact path={urls.onboardingCongrat} component={SimpleCongrat} />
              <Route exact path={urls.terms} component={Terms} />
              <Route exact path={urls.policy} component={Policy} />
              <Route exact path={urls.cookies} component={Cookies} />
              <Route exact path={urls.about} component={About} />
              <Route exact path={urls.getInTouch} component={GetInTouch} />
              <Route exact path={urls.paymentSuccess} component={PaymentCongrats} />
              <Route exact path={urls.badGateway} component={BadGateway} />
              <Route exact path={urls.accessDenied} component={AccessDenied} />
              <Route exact path={urls.tariffPlans} component={TariffPlans} />
              <Route exact path={urls.onboardingConnectionError} component={ConnectionError} />
              {/* <Route exact path={urls.onboardingConfirmInvite} component={ConfirmInvite} /> */}
              <Route exact path={urls.onboardingAcceptInviteCongrats} component={AcceptInviteCongrats} />
              <Route exact path={urls.onboardingAcceptInviteError} component={AcceptInviteError} />
              <Route exact path={urls.onboardingClaimJoin} component={ClaimJoin} />
              <Route exact path={urls.onboardingClaimPending} component={ClaimPending} />
              <Route exact path={urls.onboardingClaimCongratulations} component={ClaimCongratulations} />
            </OnboardingContentContainer>
          </Switch>
        </Route>
        <Route path={urls.volunteer}>
          <Banner />
          <MainPageLayoutV>
            <Switch>
              <RouteGuardLoginRequired
                path={urls.volFeedViewPath}
                component={FeedViewItem}
                accessRoles={[VOLUNTEER.VOLUNTEER]}
              />
              <RouteGuardWithGuest path={urls.volFeed} component={FeedPage} accessRoles={[VOLUNTEER.VOLUNTEER]} />

              {!userData?.id ? (
                <Loader loadProps={{stylePageCenter: true}} />
              ) : (
                <>
                  <RouteGuard path={urls.volMyFeed} component={MyFeedPage} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                  <RouteGuard path={urls.volHome} component={HomePage} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                  <RouteGuard path={urls.volDiploma} component={DiplomaPage} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                  <RouteGuard path={urls.volInbox} component={VolunteerChat} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                  <RouteGuard
                    path={urls.volEditAccount}
                    component={EditVolunteer}
                    accessRoles={[VOLUNTEER.VOLUNTEER]}
                  />
                  <RouteGuard path={urls.volAbout} component={About} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                  <RouteGuard
                    path={urls.volUpdateManualOpportunity}
                    component={UpdateManualOpportunity}
                    accessRoles={[VOLUNTEER.VOLUNTEER]}
                  />
                  <RouteGuard
                    path={urls.volManualOpportunity}
                    component={CreateManualOppoV}
                    accessRoles={[VOLUNTEER.VOLUNTEER]}
                  />
                  <RouteGuard path={urls.volGetInTouch} component={GetInTouch} accessRoles={[VOLUNTEER.VOLUNTEER]} />
                </>
              )}
            </Switch>
          </MainPageLayoutV>
        </Route>
        <Route path={urls.org}>
          <Banner />
          {!userData?.id ? (
            <Loader loadProps={{stylePageCenter: true}} />
          ) : (
            <Switch>
              <MainPageLayout>
                <RouteGuard
                  path={urls.orgDashboard}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR, VOLUNTEER.MARKETER]}
                  component={DashboardPage}
                />
                <RouteGuard
                  path={urls.orgOpportunities}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR]}
                  component={OpportunitiesPage}
                />
                <RouteGuard
                  path={urls.orgVolunteers}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR, VOLUNTEER.MARKETER]}
                  component={VolunteersPage}
                />
                <RouteGuard path={urls.orgMembers} accessRoles={[VOLUNTEER.ADMIN]} component={MembersPage} />
                <RouteGuard
                  path={urls.orgMarketing}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.MARKETER]}
                  component={MarketingPage}
                />
                <RouteGuard
                  path={urls.orgInbox}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR, VOLUNTEER.MARKETER]}
                  component={InboxPage}
                />
                <RouteGuard
                  path={urls.orgStatistics}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR, VOLUNTEER.MARKETER]}
                  component={Statistics}
                />
                <RouteGuard
                  path={urls.orgEditAccount}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR, VOLUNTEER.MARKETER]}
                  component={EditAccount}
                />
                <RouteGuard
                  path={urls.orgEditSettings}
                  accessRoles={[VOLUNTEER.ADMIN, VOLUNTEER.COORDINATOR]}
                  component={OrganizationSettings}
                />
                <RouteGuard
                  path={urls.orgInviteColleagues}
                  accessRoles={[VOLUNTEER.ADMIN]}
                  component={InviteColleagues}
                />
              </MainPageLayout>
            </Switch>
          )}
        </Route>
        <RouteGuardLoginRequired
          path={urls.newMainFeed}
          accessRoles={[
            VOLUNTEER.ADMIN,
            VOLUNTEER.COORDINATOR,
            VOLUNTEER.MARKETER,
            VOLUNTEER.SUPER_ADMIN,
            VOLUNTEER.VOLUNTEER,
          ]}
          component={GuestFeed}
        />

        <Route exact path={urls.landing} component={Landing} />

        {/* Not exists route */}
        <Route component={NotFound} />
      </Switch>
      <SnackbarUploadInfo />
    </>
  );
};
const mapStateToProps = (store: Store) => ({
  userData: selectorGetUserData(store),
  initialText: selectorGetPushMessage(store),
  orgInfo: selectorGetOrgInfo(store),
  messagesUnreadCount: selectorGetChatUnreadMessagesCount(store),
  userToConnect: selectorGetChatUserToConnect(store),
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  getOrgInfo,
  getCurrentUser,
  setPushMessage,
  setUserNotificationToken,
  setChatUnreadMessagesCount,
  setChatUserToConnect,
  connectUserToGetStream,
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppPropsType = ConnectedProps<typeof connector>;

export default connector(App);
