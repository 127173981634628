import {createContext, Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Formik} from 'formik';
import moment from 'moment';
//redux
import {Store} from 'redux/root';
import {createManualOpportunity} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
//urls
import {urls} from 'core/appUrls';
//validation
import {dynamicManualOppoValidation, opportunityManualValidationSchema} from 'core/validation';
//constants
import {OPPORTUNITIES_INITIAL_VALUES_MANUAL} from 'core/constants';
//types
import {IOrganisationOpportunitySettingsResponse, OPPORTUNITY_TYPES} from '@joc/api-gateway/lib/api-client';
import {ManualOpportunityInitialValuesType} from 'core/types';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import CongratsPopup from 'components/Organization/CongratsPopup';
import ManualOpportunityMainForm from './ManualOpportunityMainForm';
//styles
import styles from './CreateManualOppoV.module.scss';
import {getOpportunitySettingsForVolunteer} from '../../../redux/organization-service/actions';
import {selectorGetOppoSettingsForVolunteer} from '../../../redux/organization-service/selector';
import {selectorGetHashtags} from '../../../redux/hashtag-service/selectors';

type ManualOppoContextType = {organizationId: string | null; setSelectedOrgId: Dispatch<SetStateAction<string>>};

export const ManualOppoContext = createContext<ManualOppoContextType>({} as ManualOppoContextType);

const CreateManualOppoV: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState(false);
  const [organizationId, setSelectedOrgId] = useState<string>('');

  const error = useSelector((store: Store) => store.errorRedux.error);
  const userVolunteerId = useSelector((store: Store) => store.userRedux.userData?.volunteerId);
  const oppoSettingsForVolunteer = useSelector(selectorGetOppoSettingsForVolunteer);
  const userId = useSelector((store: Store) =>
    store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined
  );

  const orgHashtags = useSelector(selectorGetHashtags);

  const dataPreset = {
    ...OPPORTUNITIES_INITIAL_VALUES_MANUAL,
    startDate: new Date(),
    endDate: new Date(),
    startTime: moment().add(1, 'hours').format('HH:mm'),
  };

  const [initValues, setInitValues] = useState({
    ...dataPreset,
    opportunityTypes: OPPORTUNITY_TYPES.MANUAL,
    volunteerId: userVolunteerId ? +userVolunteerId : 0,
    user: userId ? +userId : 0,
  });

  useEffect(() => {
    const values = localStorage.getItem('manual');
    if (values) setInitValues(JSON.parse(values));
  }, [error.state]);

  const submitClickHandler = async (values: ManualOpportunityInitialValuesType): Promise<void> => {
    if (!userVolunteerId) throw new Error(t('errors:cantFindVolunteerIdRefresh'));
    if (!userId) throw new Error(t('errors:cantFindUserIdRefresh'));
    setIsLoading(true);
    try {
      const body = document.createElement('body');
      body.innerHTML = values.opportunityDescription;
      await dispatch(createManualOpportunity({...values, opportunityDescription: body.outerHTML}));
      setIsSubmitted(true);
    } catch (err: any) {
      dispatch(setError(err?.response?.message || err.message, err?.response?.code || err.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handlerResetError = () => dispatch(resetError());

  useEffect(() => {
    if (!oppoSettingsForVolunteer.length) {
      dispatch(getOpportunitySettingsForVolunteer());
    }
  }, [oppoSettingsForVolunteer]);

  const mandatoryFields = useMemo<IOrganisationOpportunitySettingsResponse | null>(() => {
    if (oppoSettingsForVolunteer.length && organizationId) {
      const orgSettings = oppoSettingsForVolunteer.find(
        (settings) => Number(settings.opportunitySettings?.organisationId) === Number(organizationId)
      )?.opportunitySettings;

      if (orgSettings) {
        return Object.assign(
          {},
          ...(Object.keys(orgSettings) as Array<keyof IOrganisationOpportunitySettingsResponse>).map((key) => {
            if (key === 'mandatoryTag') {
              return {[key]: orgSettings[key] && !!orgHashtags.length && organizationId};
            }

            return {[key]: orgSettings[key]};
          })
        );
      }
    }

    return null;
  }, [oppoSettingsForVolunteer, organizationId, orgHashtags]);

  const validation = useMemo(() => {
    if (mandatoryFields) {
      return dynamicManualOppoValidation(mandatoryFields);
    }
    return opportunityManualValidationSchema;
  }, [mandatoryFields]);

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonTitle={t('errors:checkAndTryAgain')}
          buttonClickHandler={handlerResetError}
        />
      ) : isSubmitted ? (
        <CongratsPopup
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:createdManualChesedOppo')}
          doneButtonClickHandler={() => history.push(urls.volMyFeed)}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:proposeManOppo.title')}</div>
          <div className={styles.form__container}>
            <ManualOppoContext.Provider value={{organizationId, setSelectedOrgId}}>
              <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={validation}
                onSubmit={(values) => submitClickHandler(values as any)}
              >
                <ManualOpportunityMainForm
                  isLoading={isLoading}
                  isVisibleHashtagField={isVisibleHashtagField}
                  setIsVisibleHashtagField={setIsVisibleHashtagField}
                  mandatoryFields={mandatoryFields}
                />
              </Formik>
            </ManualOppoContext.Provider>
          </div>
        </Appeal>
      )}
    </Fragment>
  );
};

export default CreateManualOppoV;
